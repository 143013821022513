import { get } from 'lodash-es'

export const ERRORS = () => {
  const { locale = 'es' } = useAppConfig().netzo
  return ({
    en: {
      notAuthorized: () => `You are not authorized to perform this action`,
      userAlreadyExists: () => `User already exists`,
      userAlreadyHasAPasskey: () => `User already has a passkey`,
      passwordUpdated: () => `Password updated successfully`,
      failedToFetchUserData: () => `Failed to fetch user data`,
      failedToCreateNewUser: () => `Failed to create new user`,
      failedToDeleteUser: () => `Failed to delete user`,
      userNotFound: () => `User not found`,
      userDeleted: () => `User deleted successfully`,
      emailNotVerified: () => `User has not verified their email`,
      otpSent: () => `One Time Password sent to your email`,
      passwordResetLinkSent: () => `Password reset link sent to your email`,
      passwordResetLinkExpired: () => `Password reset link has expired`,
      passwordResetTokenExpired: () => `Password reset token has expired`,
      invalidCredentials: () => `Invalid credentials`,
      accountBanned: () => `Your account has been banned`,
      internalServerError: () => `Internal server error`,
      accountDeleted: () => `Account deleted successfully`,
      passwordReset: () => `Password reset successfully`,
      emailNotFound: () => `Email not found`,
      missingToken: () => `Missing token`,
      invalidVerificationCode: () => `Invalid verification code`,
      expiredVerificationCode: () => `Verification code has expired`,
      invalidOTP: () => `Invalid one time password (OTP)`,
      expiredOTP: () => `One time password (OTP) has expired`,
      accountIdRequired: () => `Account ID is required`,
      linkedAccountNotFound: () => `Linked account not found`,
      accountUnlinked: () => `Account unlinked successfully`,
      userHasNotRegisteredWithPasskey: () => `User has not registered with passkey`,
      passkeyNotFound: () => `Passkey not found`,
      passkeyDeleted: () => `Passkey deleted successfully`,
      missingCredential: () => `Missing credential`,
      userExistsAlready: () => `User exists already`,
      missingPasskeyVerificationCredentials: () => `Missing passkey verification credentials`,
      failedToSendEmail: () => `Failed to send email`,
    },
    es: {
      notAuthorized: () => `No estás autorizado para realizar esta acción`,
      userAlreadyExists: () => `El usuario ya existe`,
      userAlreadyHasAPasskey: () => `El usuario ya tiene un passkey`,
      passwordUpdated: () => `Contraseña actualizada correctamente`,
      failedToFetchUserData: () => `Error al obtener los datos del usuario`,
      failedToCreateNewUser: () => `Error al crear un nuevo usuario`,
      failedToDeleteUser: () => `Error al eliminar el usuario`,
      userNotFound: () => `Usuario no encontrado`,
      userDeleted: () => `Usuario eliminado correctamente`,
      emailNotVerified: () => `El usuario no ha verificado su email`,
      otpSent: () => `Se ha enviado una contraseña de un solo uso a tu email`,
      passwordResetLinkSent: () => `Se ha enviado un enlace para restablecer la contraseña a tu email`,
      passwordResetLinkExpired: () => `El enlace para restablecer la contraseña ha caducado`,
      passwordResetTokenExpired: () => `El token para restablecer la contraseña ha caducado`,
      invalidCredentials: () => `Credenciales inválidas`,
      accountBanned: () => `Tu cuenta ha sido bloqueada`,
      internalServerError: () => `Error interno del servidor`,
      accountDeleted: () => `Cuenta eliminada correctamente`,
      passwordReset: () => `Contraseña restablecida correctamente`,
      emailNotFound: () => `Email no encontrado`,
      missingToken: () => `Falta el token`,
      invalidVerificationCode: () => `Código de verificación inválido`,
      expiredVerificationCode: () => `El código de verificación ha caducado`,
      invalidOTP: () => `Contraseña de un solo uso (OTP) inválida`,
      expiredOTP: () => `La contraseña de un solo uso (OTP) ha caducado`,
      accountIdRequired: () => `Se requiere el ID de la cuenta`,
      linkedAccountNotFound: () => `Cuenta vinculada no encontrada`,
      accountUnlinked: () => `Cuenta desvinculada correctamente`,
      userHasNotRegisteredWithPasskey: () => `El usuario no se ha registrado con el passkey`,
      passkeyNotFound: () => `Passkey no encontrado`,
      passkeyDeleted: () => `Passkey eliminado correctamente`,
      missingCredential: () => `Credencial faltante`,
      userExistsAlready: () => `El usuario ya existe`,
      missingPasskeyVerificationCredentials: () => `Faltan credenciales de verificación de passkey`,
      failedToSendEmail: () => `Error al enviar el email`,
    },
  })?.[locale]
}

export const ALERTS = () => {
  const { locale = 'es' } = useAppConfig().netzo
  return ({
    en: {
      // success:
      loggedIn: () => `Logged in`,
      otpSent: () => `OTP sent to your email`,
      otpVerified: () => `OTP has been verified`,
      accountCreatedWithPassword: () => `Account created with password`,
      accountCreatedWithPasskey: () => `Account created with passkey`,
      emailSent: () => `Email sent`,
      passwordReset: () => `Password reset`,
      userCreated: () => `User created successfully`,
      userBanned: () => `User banned successfully`,
      unlinkedAccount: () => `Account unlinked successfully`,
      createdPasskey: () => `Passkey created successfully`,
      deletedPasskey: () => `Passkey deleted successfully`,
      updatedPassword: () => `Password updated successfully`,
      updatedProfile: () => `Profile updated successfully`,
      // error:
      failedToUpdateProfile: () => `Failed to update profile`,
      failedToFetchLinkedAccounts: () => `Failed to fetch linked accounts`,
      failedToUnlinkAccount: () => `Failed to unlink account`,
      failedToCheckPasskeyStatus: () => `Failed to check passkey status`,
      failedToCreatePasskey: () => `Failed to create passkey`,
      failedToDeletePasskey: () => `Failed to delete passkey`,
      error: (message = 'An error occurred') => {
        console.error(message)
        return message
      },
    },
    es: {
      // success:
      loggedIn: () => `Sesión iniciada`,
      otpSent: () => `OTP enviado a tu email`,
      otpVerified: () => `OTP verificado`,
      accountCreatedWithPassword: () => `Cuenta creada con contraseña`,
      accountCreatedWithPasskey: () => `Cuenta creada con passkey`,
      emailSent: () => `Email enviado`,
      passwordReset: () => `Contraseña restablecida con éxito`,
      userCreated: () => `Usuario creado con éxito`,
      userBanned: () => `Usuario bloqueado con éxito`,
      unlinkedAccount: () => `Cuenta desvinculada con éxito`,
      createdPasskey: () => `Passkey creado con éxito`,
      deletedPasskey: () => `Passkey eliminado con éxito`,
      updatedPassword: () => `Contraseña actualizada con éxito`,
      updatedProfile: () => `Perfil actualizado con éxito`,
      // error:
      failedToUpdateProfile: () => `Error al actualizar el perfil`,
      failedToFetchLinkedAccounts: () => `Error al obtener las cuentas vinculadas`,
      failedToUnlinkAccount: () => `Error al desvincular la cuenta`,
      failedToCheckPasskeyStatus: () => `Error al verificar el estado del passkey`,
      failedToCreatePasskey: () => `Error al crear el passkey`,
      failedToDeletePasskey: () => `Error al eliminar el passkey`,
      error: (message = 'Se ha producido un error') => {
        console.error(message)
        return message
      },
    },
  })?.[locale]
}

/**
 * Filter data by search value
 * @example data.filter(searchBy(q, ["name", "address.city"]))
 */
export const searchBy = <T>(value: string, keys: string[]) => {
  return (row: T) => {
    if (!value) return true
    const regexp = new RegExp(value, 'i')
    return keys.some((key) => {
      const val = get(row, key)
      return val && val?.search?.(regexp) !== -1
    })
  }
}

/**
 * Filter data by key value
 * @example data.filter(filterBy(type, "type"))
 */
export const filterBy = <T>(value: string[], key: string) => {
  return (row: T) => {
    if (!value) return true
    const values = Array.isArray(value) ? value : [value]
    if (!values?.length) return true
    const val = get(row, key)
    return val && values.includes(val)
  }
}

/**
 * Sort data by key
 * @example data.sort(sortBy("name", "asc"))
 */
export const sortBy = <T>(sort: string, order: 'asc' | 'desc') => {
  return (a: T, b: T) => {
    if (!sort) return 0
    if (a[sort] < b[sort]) return order === 'asc' ? -1 : 1
    if (a[sort] > b[sort]) return order === 'asc' ? 1 : -1
    return 0
  }
}
